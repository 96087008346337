.mobileChild {
  position: absolute;
  top: 459.69rem;
  left: 106.31rem;
  background-color: var(--color-crimson);
  width: 11.44rem;
  height: 15.88rem;
}
.loggo1Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 4.38rem;
  height: 3.5rem;
  object-fit: cover;
}
.beyondCoding,
.linkplusIt {
  position: absolute;
  line-height: 2rem;
}
.linkplusIt {
  top: 0;
  left: 0;
  font-weight: 300;
}
.beyondCoding {
  top: 0.13rem;
  left: 6.63rem;
  font-size: var(--font-size-mini);
  font-weight: 200;
  display: inline-block;
  width: 10rem;
  height: 1.31rem;
  color: #d11616;
}
.linkplusItParent {
  position: absolute;
  top: 0.75rem;
  left: 6.31rem;
  width: 50rem;
  height: 2rem;
  text-align: left;
}
.bridgingTheGap {
  margin: 0;
}
.bridgingTheGapContainer {
  position: absolute;
  top: 10.13rem;
  left: 0.06rem;
  font-size: var(--font-size-3xl);
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 20.38rem;
}
.ourInnovative {
  color: var(--color-white);
}
.withOurInnovativeContainer {
  position: absolute;
  top: 14.75rem;
  left: 0.13rem;
  font-size: var(--font-size-base);
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  width: 20.31rem;
  color: var(--color-crimson);
}
.c662785d865245e784dcAdc885Icon {
  position: absolute;
  top: 20.25rem;
  left: 4.06rem;
  width: 12.38rem;
  height: 14.19rem;
  object-fit: cover;
}
.loggo1Parent {
  position: absolute;
  top: 1.63rem;
  left: 1.5rem;
  width: 20.44rem;
  height: 32.94rem;
  text-align: center;
}
.meee23UiuxDesignForAProduIcon {
  position: absolute;
  top: 4.88rem;
  left: 1.62rem;
  width: 15rem;
  height: auto;
  object-fit: cover;
}
.mee123ACloseShotOfAYoungIcon {
  object-fit: cover;
}
.groupContainer,
.mee123ACloseShotOfAYoungIcon,
.mee123ACloseShotOfAYoungWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 10em;
  height: auto;
}
.mee123ACloseShotOfAYoungWrapper {
  box-shadow: 0 4px 100px #000;
}
.groupContainer {
  box-shadow: 0 4px 50px #000;
}
.groupWrapper,
.meee23UiuxDesignForAProduParent {
  position: absolute;
  top: 0;
  width: 18.13rem;
  height: 15.35rem;
}
.meee23UiuxDesignForAProduParent {
  left: 0;
}
.groupWrapper {
  left: 1.19rem;
}
.highlights {
  color: var(--color-gold);
}
.highlightsOfOurContainer {
  position: absolute;
  top: 19.56rem;
  left: 1.75rem;
  font-size: var(--font-size-16xl);
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 16.94rem;
}
.groupChild {
  top: 0;
  background-color: var(--color-gold);
  height: 13.04rem;
}
.groupChild,
.groupItem,
.rectangleParent {
  position: absolute;
  left: 0;
  width: 1rem;
}
.groupItem {
  top: 1.64rem;
  box-sizing: border-box;
  height: 13.04rem;
}
.rectangleParent {
  top: 31.44rem;
  height: 14.69rem;
}
.groupInner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-black);
  width: 7.81rem;
  height: 13.06rem;
}
.taxiLimousine {
  position: absolute;
  top: 4.06rem;
  left: 1.19rem;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 5.45rem;
  height: 4.94rem;
}
.rectangleContainer,
.rectangleGroup {
  position: absolute;
  top: 29rem;
  width: 30rem;
  height: 35rem;
}
.rectangleGroup {
  left: 3.19rem;
}
.rectangleContainer {
  left: 11.81rem;
}
.groupIcon {
  position: absolute;
  top: calc(50% + 450px);
  right: 8.81rem;
  width: 5.94rem;
  height: 2.19rem;
}
.groupIcon22 {
  position: absolute;
  top: calc(50% + 450px);
  right: 3.81rem;
  width: 5.94rem;
  height: 2.19rem;
}
.groupParent,
.mobileInner {
  position: absolute;
  width: 19.63rem;
  height: 48.63rem;
}
.groupParent {
  top: 0;
  left: 0;
}
.mobileInner {
  top: 401rem;
  left: 3rem;
  text-align: center;
  font-size: var(--font-size-sm);
}
.majkmSimplySectionWebsiteSIcon,
.sds1Icon {
  position: absolute;
  object-fit: cover;
}
.sds1Icon {
  top: -2.87rem;
  left: -1.94rem;
  width: 19.81rem;
  height: 21.36rem;
}
.majkmSimplySectionWebsiteSIcon {
  top: 8.88rem;
  left: 7.43rem;
  width: 14.07rem;
  height: 10.75rem;
}
.groupFrame,
.sds1Parent {
  position: absolute;
  width: 19.83rem;
  height: 16.88rem;
}
.sds1Parent {
  top: 0;
  left: 0;
}
.groupFrame {
  top: 28.44rem;
  left: 0.88rem;
}
.javaEeSpring,
.technologies {
  position: absolute;
  left: 0;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.technologies {
  top: 4.63rem;
  width: 8.63rem;
}
.javaEeSpring {
  top: 7.25rem;
  font-size: var(--font-size-sm);
  font-weight: 200;
  width: 9.31rem;
}
.groupChild1 {
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(50% 50%at 50% 50%, #ed4d4d, #d11616);
  width: 2.81rem;
  height: 2.81rem;
}
.technologiesParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 9.31rem;
  height: 15.25rem;
}
.databases,
.mysqlMongodbPostgresql {
  position: absolute;
  top: 4.63rem;
  left: 0;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 7.51rem;
}
.mysqlMongodbPostgresql {
  top: 7.25rem;
  font-size: var(--font-size-sm);
  font-weight: 200;
  width: 9.69rem;
}
.groupChild2 {
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.67rem;
}
.databasesParent,
.groupWrapper2 {
  position: absolute;
  top: 0;
  width: 9.69rem;
  height: 11.25rem;
}
.databasesParent {
  left: 0;
}
.groupWrapper2 {
  left: 10.56rem;
}
.groupParent1 {
  position: absolute;
  top: 53.88rem;
  left: 0.75rem;
  width: 20.25rem;
  height: 15.25rem;
}
.qualityAssurance,
.seleniumGherkinManual {
  position: absolute;
  left: 0;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.qualityAssurance {
  top: 0;
  width: 9.56rem;
}
.seleniumGherkinManual {
  top: 4.63rem;
  font-size: var(--font-size-sm);
  font-weight: 200;
  width: 8.89rem;
}
.groupWrapper4,
.qualityAssuranceParent {
  position: absolute;
  left: 0;
  width: 9.56rem;
  height: 10.63rem;
}
.qualityAssuranceParent {
  top: 0;
}
.groupWrapper4 {
  top: 5.13rem;
}
.groupChild3,
.groupParent3 {
  position: absolute;
  top: 0;
  left: 0;
}
.groupChild3 {
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent3 {
  width: 9.56rem;
  height: 15.75rem;
}
.businessIntelligence,
.dataAnalysisreportBuilding {
  position: absolute;
  top: 4.81rem;
  left: 0;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 9.69rem;
}
.dataAnalysisreportBuilding {
  top: 9.19rem;
  font-size: var(--font-size-sm);
  font-weight: 200;
  width: 8.21rem;
}
.businessIntelligenceParent {
  position: absolute;
  top: 0;
  left: 10.56rem;
  width: 9.69rem;
  height: 15.19rem;
}
.groupParent2 {
  position: absolute;
  top: 74.19rem;
  left: 0.75rem;
  width: 20.25rem;
  height: 15.75rem;
}
.mobile1,
.reactNativeKotlin {
  position: absolute;
  left: 0;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.mobile1 {
  top: 0;
  width: 4.69rem;
}
.reactNativeKotlin {
  top: 2.63rem;
  font-size: var(--font-size-sm);
  font-weight: 200;
  width: 10.31rem;
}
.mobileParent {
  top: 0;
  height: 6.63rem;
}
.groupParent5,
.groupWrapper5,
.mobileParent {
  position: absolute;
  left: 0;
  width: 10.31rem;
}
.groupWrapper5 {
  top: 4.56rem;
  height: 6.63rem;
}
.groupParent5 {
  top: 0;
  height: 11.19rem;
}
.agileScrum,
.projectManagement {
  position: absolute;
  left: 0;
  line-height: 2rem;
  display: inline-block;
}
.projectManagement {
  top: 0;
  text-transform: uppercase;
  width: 7.88rem;
}
.agileScrum {
  top: 4.63rem;
  font-size: var(--font-size-sm);
  text-transform: capitalize;
  font-weight: 200;
  width: 8.81rem;
}
.groupWrapper6,
.projectManagementParent {
  position: absolute;
  left: 0;
  width: 8.81rem;
  height: 8.63rem;
}
.projectManagementParent {
  top: 0;
}
.groupWrapper6 {
  top: 4.56rem;
}
.groupParent4,
.groupParent6 {
  position: absolute;
  height: 13.19rem;
}
.groupParent6 {
  top: 0;
  left: 10.56rem;
  width: 8.81rem;
}
.groupParent4 {
  top: 95rem;
  left: 0.75rem;
  width: 19.38rem;
}
.technology {
  top: 0;
  left: 5.44rem;
  line-height: 0.63rem;
  text-transform: uppercase;
  font-weight: 300;
  width: 6.56rem;
}
.ourExpertiseLies,
.technology,
.whatWeUse {
  position: absolute;
  display: inline-block;
}
.whatWeUse {
  top: 3.75rem;
  left: 2.13rem;
  font-size: var(--font-size-6xl);
  line-height: 0.63rem;
  text-transform: uppercase;
  width: 13.13rem;
}
.ourExpertiseLies {
  top: 7.5rem;
  left: 0;
  font-size: var(--font-size-sm);
  line-height: 2rem;
  font-weight: 300;
  text-align: center;
  width: 17.38rem;
}
.technologyParent {
  position: absolute;
  top: 8.19rem;
  left: 3.94rem;
  width: 17.38rem;
  height: 15.5rem;
  font-size: var(--font-size-base);
}
.stack,
.technology1,
.weUse {
  position: absolute;
  left: 2rem;
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.technology1 {
  top: 0;
  width: 9.19rem;
}
.stack {
  top: 15.44rem;
  width: 4.69rem;
}
.weUse {
  top: 26.38rem;
  width: 5.38rem;
}
.technologyGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 2rem;
  height: 31.75rem;
  font-size: var(--font-size-base);
}
.groupDiv {
  position: absolute;
  top: 281.38rem;
  left: 2rem;
  width: 21.31rem;
  height: 108.19rem;
  font-size: var(--font-size-lg);
}
.dsjfkdsjf1Icon {
  position: absolute;
  top: -2.25rem;
  left: 2.38rem;
  width: 15.88rem;
  height: 15.81rem;
  object-fit: cover;
}
.industries {
  margin: 0;
  color: var(--color-crimson);
}
.joinTheTeamContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 20.06rem;
}
.joinUsNow {
  position: relative;
  line-height: 2rem;
  font-weight: 300;
  text-decoration: none;
  background-color: transparent;
  color: white;
}
.joinUsNowWrapper {
  text-decoration: none;
  position: absolute;
  top: 7.56rem;
  left: 0;
  border: 1px solid var(--color-white);
  box-sizing: border-box;
  width: 20.44rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-mini) var(--padding-81xl);
  align-items: flex-start;
  justify-content: flex-start;
  text-align: right;
  font-size: var(--font-size-base);
}
.joinTheTeamRevolutionizingParent {
  position: absolute;
  top: 12.75rem;
  left: 0.06rem;
  width: 20.44rem;
  height: 11.44rem;
  text-align: center;
  font-size: var(--font-size-6xl);
}
.experienceThePower {
  position: relative;
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  width: 19.94rem;
  flex-shrink: 0;
}
.experienceThePowerOfTeamCWrapper {
  position: absolute;
  top: 28.13rem;
  left: 0;
  border-bottom: 0.1px solid var(--color-white);
  box-sizing: border-box;
  width: 20.56rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.advanceYourCareer {
  position: relative;
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  width: 19.88rem;
  flex-shrink: 0;
}
.advanceYourCareerWithCompeWrapper {
  position: absolute;
  top: 53.69rem;
  left: 0;
  border-bottom: 0.1px solid var(--color-white);
  box-sizing: border-box;
  width: 20.5rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.joinAFlexible {
  position: relative;
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  width: 19.81rem;
  flex-shrink: 0;
}
.experienceTheBestOfBothWoWrapper,
.joinAFlexibleForwardThinkWrapper {
  position: absolute;
  border-bottom: 0.1px solid var(--color-white);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.joinAFlexibleForwardThinkWrapper {
  top: 36.19rem;
  left: 0.06rem;
  width: 20.44rem;
}
.experienceTheBestOfBothWoWrapper {
  top: 44.25rem;
  left: 0;
  width: 20.5rem;
}
.dsjfkdsjf1Parent {
  position: absolute;
  top: 457.06rem;
  left: 1.44rem;
  width: 20.56rem;
  height: 58.94rem;
  font-size: var(--font-size-sm);
}
.linkplusIt1 {
  position: absolute;
  top: 0;
  left: 8.06rem;
  line-height: 2rem;
  font-weight: 300;
  text-align: left;
}
.thisWebsiteWasContainer {
  position: absolute;
  top: 2.88rem;
  left: 0.63rem;
  font-size: var(--font-size-xl);
  line-height: 2rem;
  text-transform: uppercase;
}
.weHaveUsedContainer {
  position: absolute;
  top: 9.69rem;
  left: 0.19rem;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  font-weight: 300;
  display: inline-block;
  width: 25rem;
  text-align: left;
}
.caretLeftIcon {
  position: absolute;
  top: calc(50% - 1.06px);
  right: 19.85rem;
  width: 0.91rem;
  height: 0.91rem;
  overflow: hidden;
}
.meee23UiuxDesignForAProduIcon1 {
  position: absolute;
  top: 0.87rem;
  left: 0;
  width: 5.83rem;
  height: 3.83rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon2,
.meee23UiuxDesignForAProduIcon3 {
  position: absolute;
  top: 4.21rem;
  left: 0.7rem;
  width: 5.62rem;
  height: 3.06rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon3 {
  top: 1.72rem;
  left: 11.95rem;
  width: 5.04rem;
  height: 2.98rem;
}
.meee23UiuxDesignForAProduIcon4,
.meee23UiuxDesignForAProduIcon5 {
  position: absolute;
  top: 3.79rem;
  left: 13.21rem;
  width: 7.42rem;
  height: 4.38rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon5 {
  top: 3.42rem;
  left: 3.68rem;
  width: 6.29rem;
  height: 4.64rem;
}
.meee23UiuxDesignForAProduIcon6,
.viberImage2023031415484Icon {
  position: absolute;
  top: 0.87rem;
  left: 6.32rem;
  width: 5.28rem;
  height: 3.28rem;
  object-fit: cover;
}
.viberImage2023031415484Icon {
  top: 5.02rem;
  left: 7.66rem;
  width: 7.89rem;
  height: 2.55rem;
}
.capture61Icon,
.mmm23LaptopPhonePixarStyleIcon {
  position: absolute;
  top: 2.42rem;
  left: 14.48rem;
  width: 2.87rem;
  height: 6.21rem;
  object-fit: cover;
}
.mmm23LaptopPhonePixarStyleIcon {
  top: 1.13rem;
  left: 16.02rem;
  width: 3.08rem;
  height: 3.08rem;
}
.meee23UiuxDesignForAProduIcon7 {
  top: 0;
  left: 4.1rem;
  width: 2.51rem;
  height: 2.51rem;
}
.createMeALandingpage1,
.meee23UiuxDesignForAProduIcon7,
.meeeeYoungHappyManProgrammIcon {
  position: absolute;
  object-fit: cover;
}
.meeeeYoungHappyManProgrammIcon {
  top: 2.3rem;
  left: 10.36rem;
  width: 4.08rem;
  height: 2.72rem;
}
.createMeALandingpage1 {
  top: 2.93rem;
  left: 18.04rem;
  width: 2.72rem;
  height: 3.91rem;
}
.caretLeftParent {
  position: absolute;
  top: calc(50% + 61px);
  right: 0;
  width: 20.76rem;
  height: 8.63rem;
}
.linkplusItGroup {
  position: absolute;
  top: 523.44rem;
  left: 1.31rem;
  width: 20.76rem;
  height: 24.88rem;
  text-align: center;
  font-size: var(--font-size-base);
}
.sadsad3Icon {
  position: absolute;
  top: -1.62rem;
  left: 2.63rem;
  width: 16.56rem;
  height: 21.38rem;
  object-fit: cover;
}
.poweringYourSuccessContainer {
  position: absolute;
  top: 5.81rem;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 21.81rem;
}
.sadsad3Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 21.81rem;
  height: 17.63rem;
  text-align: center;
  color: var(--color-white);
}
.accessToA,
.staffAugmentation {
  position: absolute;
  line-height: 2rem;
  display: inline-block;
}
.staffAugmentation {
  top: 0;
  left: 1.38rem;
  text-transform: uppercase;
  font-weight: 600;
  width: 14.69rem;
}
.accessToA {
  top: 2.94rem;
  left: 0;
  font-size: var(--font-size-sm);
  font-weight: 300;
  color: var(--color-white);
  text-align: center;
  width: 17.5rem;
}
.staffAugmentationParent {
  position: absolute;
  top: 46.88rem;
  left: 2.19rem;
  width: 17.5rem;
  height: 8.94rem;
}
.endToEndSolutions {
  position: absolute;
  top: 0.06rem;
  left: 1.69rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 15.56rem;
  transform: rotate(-0.22deg);
  transform-origin: 0 0;
}
.weHandleEvery {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.weHandleEveryContainer {
  position: absolute;
  top: 4.52rem;
  left: 0;
  font-size: var(--font-size-base);
  line-height: 2rem;
  font-weight: 300;
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  width: 19.19rem;
}
.endToEndSolutionsParent {
  position: absolute;
  top: 22.81rem;
  left: 1.38rem;
  width: 19.19rem;
  height: 18.83rem;
}
.groupParent7,
.mobileInner1 {
  position: absolute;
  width: 21.81rem;
  height: 55.81rem;
}
.groupParent7 {
  top: 0;
  left: 0;
}
.mobileInner1 {
  top: 218.13rem;
  left: 0.81rem;
  color: var(--color-crimson);
}
.linkplusIt3 {
  position: absolute;
  top: 6.94rem;
  left: 7.88rem;
  line-height: 2rem;
  font-weight: 300;
}
.softwareDevelopment {
  color: var(--color-crimson);
}
.employsAHolisticContainer,
.methodology {
  position: absolute;
  line-height: 2rem;
  text-transform: uppercase;
}
.employsAHolisticContainer {
  top: 9.5rem;
  left: 0.06rem;
  font-size: var(--font-size-3xl);
  display: inline-block;
  text-align: center;
  width: 20.44rem;
}
.methodology {
  top: 0;
  left: 3.06rem;
  font-size: var(--font-size-11xl);
  font-weight: 600;
  color: var(--color-crimson);
}
.meee23UiuxDesignForAProduIcon8 {
  position: absolute;
  top: 25.06rem;
  left: 5.13rem;
  width: 10.25rem;
  height: 10.25rem;
  object-fit: cover;
}
.weUnderstandThat {
  position: absolute;
  top: 18.56rem;
  left: 0;
  font-size: var(--font-size-sm);
  line-height: 1.25rem;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  width: 20.5rem;
}
.linkplusItContainer {
  position: absolute;
  top: 175.38rem;
  left: 1.5rem;
  width: 20.5rem;
  height: 35.31rem;
  font-size: var(--font-size-base);
}
.aboutUs {
  position: absolute;
  top: 0;
  left: 6.94rem;
  line-height: 2rem;
  font-weight: 300;
}
.unlockingThePowerContainer {
  position: absolute;
  top: 2.75rem;
  left: 0;
  font-size: var(--font-size-6xl);
  line-height: 2.19rem;
  text-transform: uppercase;
  font-weight: 800;
}
.aboutUsParent,
.establishedInNovember {
  position: absolute;
  color: var(--color-white);
  text-align: center;
}
.aboutUsParent {
  top: 11.06rem;
  left: 0.63rem;
  width: 19.19rem;
  height: 9.31rem;
  font-size: var(--font-size-lg);
}
.establishedInNovember {
  top: 0;
  left: 0;
  font-size: var(--font-size-base);
  line-height: 2rem;
  font-weight: 200;
  display: inline-block;
  width: 20.44rem;
}
.roadmap {
  position: relative;
  left: -0.6rem;
  top: -0.3rem;
  line-height: 2rem;
  text-transform: capitalize;
  background-color: transparent;
  border: none;
  color: white;
}
.roadmapWrapper {
  position: absolute;
  top: 23.44rem;
  left: 0.13rem;
  background-color: var(--color-gray-100);
  border: 0.5px solid var(--color-white);
  box-sizing: border-box;
  width: 20.06rem;
  height: 3.31rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-61xl);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
}
.roadmapWrapper button {
  border: none;
  color: white;
  background-color: transparent;
}
.linkplusLinkplusIt {
  margin: 0;
  font-weight: 300;
  color: var(--color-white);
}
.in2021LinkplusContainer,
.linkplusLinkplusItContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  display: inline-block;
}
.linkplusLinkplusItContainer {
  width: 9.75rem;
}
.in2021LinkplusContainer {
  width: 10rem;
}
.in2021LinkplusItContinuedWrapper {
  position: absolute;
  top: 0;
  left: 10.75rem;
  width: 10rem;
  height: 14rem;
}
.linkplusLinkplusItWasAwardParent {
  position: absolute;
  top: 52.56rem;
  left: 0;
  width: 20.75rem;
  height: 16rem;
}
.weWereAbleContainer {
  position: absolute;
  top: 0;
  left: 11rem;
  line-height: 2rem;
  display: inline-block;
  width: 10rem;
}
.weWereAbleToAttractMoreCParent {
  position: absolute;
  top: 71.69rem;
  left: 0;
  width: 21rem;
  height: 16rem;
}
.linkplusItNotContainer {
  position: absolute;
  top: 0;
  left: 10.94rem;
  line-height: 2rem;
  display: inline-block;
  width: 10rem;
}
.linkplusItNotOnlyContinuedParent {
  position: absolute;
  top: 90.81rem;
  left: 0;
  width: 20.94rem;
  height: 20rem;
}
.thisYearMarkedATurningPoiParent {
  position: absolute;
  top: 113.94rem;
  left: 0.06rem;
  width: 20.94rem;
  height: 16rem;
}
.asds1Icon,
.meee23UiuxDesignForAProduIcon9 {
  position: absolute;
  top: -1.56rem;
  left: -2.5rem;
  width: 17.19rem;
  height: 20.89rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon9 {
  top: 12.28rem;
  left: 3.9rem;
  width: 10.1rem;
  height: 8.62rem;
}
.asds1Parent {
  width: 12.75rem;
  height: 19.4rem;
}
.asds1Parent,
.groupParent8,
.groupWrapper7 {
  position: absolute;
}
.groupWrapper7 {
  width: 12.75rem;
  height: 19.4rem;
}
.asds1Parent {
  top: 0;
  left: 0;
}
.groupWrapper7 {
  top: 31.25rem;
  left: 7.5rem;
}
.groupParent8 {
  top: 38rem;
  left: 2rem;
  width: 21rem;
  height: 129.94rem;
  font-size: var(--font-size-3xs);
  color: var(--color-crimson);
}
.pristinaKosovo {
  position: absolute;
  top: 0;
  left: 3.25rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 10.81rem;
  height: 1.44rem;
}
.strTiranaIcon {
  margin-block-start: 0;
  margin-block-end: 10px;
}
.strTiranaIconContainer {
  position: absolute;
  top: 3.63rem;
  left: 0;
  font-size: var(--font-size-base);
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  width: 17.25rem;
}
.pristinaKosovoParent {
  position: absolute;
  top: 0;
  left: 0.31rem;
  width: 17.25rem;
  height: 8.25rem;
}
.skopjeNorthMacedoniaContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 16.56rem;
  height: 1.44rem;
}
.borisTrajkovski1Container {
  position: absolute;
  top: 3.63rem;
  left: 0.13rem;
  font-size: var(--font-size-base);
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  width: 16.31rem;
}
.skopjeNorthMacedoniaParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 16.56rem;
  height: 8.25rem;
}
.emailInfolinkplusItcom {
  position: absolute;
  top: 11.69rem;
  left: 1.19rem;
  font-size: var(--font-size-base);
  line-height: 2rem;
  font-weight: 300;
  color: var(--color-crimson);
  display: inline-block;
  width: 14.25rem;
}
.groupParent12 {
  position: absolute;
  top: 18.69rem;
  left: 0;
  width: 16.56rem;
  height: 13.69rem;
}
.groupWrapper8,
.groupWrapper9 {
  position: absolute;
  width: 17.56rem;
  height: 32.38rem;
}
.groupWrapper9 {
  top: 0;
  left: 0;
}
.groupWrapper8 {
  top: 39.75rem;
  left: 1.31rem;
}
.groupChild7,
.name {
  position: absolute;
  left: 0;
}
.name {
  top: 0;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  display: inline-block;
  width: 3.01rem;
}
.groupChild7 {
  top: 0.25rem;
  box-sizing: border-box;
  width: 19.18rem;
  height: 3.75rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  background-color: transparent;
  padding-top: 1rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
}
.email {
  top: 7.81rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  display: inline-block;
  width: 2.65rem;
}
.email,
.groupChild8,
.message {
  position: absolute;
  left: 0;
}
.groupChild8 {
  top: 8.06rem;
  box-sizing: border-box;
  width: 19.18rem;
  height: 3.75rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  background-color: transparent;
  padding-top: 1rem;
  color: white;
  text-decoration: none;
  font-size: 1rem;
}
.message {
  top: 15.63rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  display: inline-block;
  width: 4.44rem;
}
.groupChild9,
.sendWrapper {
  position: absolute;
  box-sizing: border-box;
}
.groupChild9:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.groupChild8:focus{
  border: none;
  outline: none;
  background-color: #212121;
}
.groupChild7:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.sendWrapper {
  cursor: pointer;
  top: 28.19rem;
  left: 2.66rem;
  background-color: var(--color-crimson);
  width: 13.86rem;
  height: 2.81rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-81xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.groupChild9 {
  top: 18.75rem;
  left: 0;
  width: 19.18rem;
  height: 8.44rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  background-color: transparent;
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding-top: 4rem;
}
.groupParent10,
.nameParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 19.18rem;
}
.nameParent {
  height: 31rem;
  font-size: var(--font-size-sm);
}
.groupParent10 {
  height: 72.13rem;
}
.linkplusItAll {
  position: absolute;
  top: 86.63rem;
  left: 1.13rem;
  font-size: var(--font-size-base);
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
}
.groupIcons {
  color: #d11616;
  margin: 0 0.5rem;
  text-decoration: none;
}
.groupChild10 {
  position: absolute;
  height: 1.27%;
  width: 100%;
  top: 94.71%;
  bottom: 4.02%;
  left:1rem;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  font-size: 0.8rem;
  color: #d11616;
}
.groupParent9 {
  position: absolute;
  top: 555.75rem;
  left: 2.06rem;
  width: 19.38rem;
  height: 88.63rem;
  font-size: var(--font-size-lg);
}
.mobile {
  position: relative;
  background-color: var(--color-gray-200);
  width: 100vw;
  height: 678.81rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.sliderDivMobile {
  background-color: black;
  width: 12rem;
  height: 20rem;
  display: flex;
  padding: 1rem;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: left;
  overflow: hidden;
  word-break: break-word;
}

.sliderDivMobile h2 {
  text-align: center;
  font-size: 0.8rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.sliderDivMobile p{
  font-size: 0.7rem;
  font-weight: 100;
}

@media only screen and (max-width: 767px) and (min-width: 600px) {
  .responsiveMobile {
    position: relative;
    margin-left: 25%;
  }
}
@media only screen and (max-width: 599px) and (min-width: 500px) {
  .responsiveMobile {
    position: relative;
    margin-left: 15%;
  }
}

@media only screen and (max-width: 499px) and (min-width: 410px) {
  .responsiveMobile {
    position: relative;
    margin-left: 6.5%;
  }
}

@media only screen and (max-width: 409px) and (min-width: 390px) {
  .responsiveMobile {
    position: relative;
    margin-left: -3%;
  }
}
@media only screen and (max-width: 389px) and (min-width: 379px) {
  .responsiveMobile {
    position: relative;
    margin-left: -5%;
  }
}

@media only screen and (max-width: 378px) and (min-width: 340px) {
  .responsiveMobile {
    position: relative;
    margin-left: -5%;
  }
}
@media only screen and (max-width: 339px) and (min-width: 1px) {
  .responsiveMobile {
    position: relative;
    margin-left: -5%;
  }
}

/*@media screen and (min-width: 412px) {*/
/*  .responsiveMobile {*/
/*    position: relative;*/
/*    margin:10%;*/
/*  }*/
/*}*/

/*@media screen and (min-width: 459px) {*/
/*  .responsiveMobile {*/
/*    position: relative;*/
/*    margin:20%;*/
/*  }*/
/*}*/

