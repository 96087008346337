.tabletChild {
  position: absolute;
  top: 459.69rem;
  left: 131.25rem;
  background-color: var(--color-crimson);
  width: 11.44rem;
  height: 15.88rem;
}
.meee23UiuxDesignForAProdu {
  position: absolute;
  top: 257.69rem;
  left: 39.31rem;
  width: 32rem;
  height: 25.25rem;
}
.loggo1Icon {
  position: absolute;
  top: 0;
  left: 0;
  width: 6.25rem;
  height: 5.01rem;
  object-fit: cover;
}
.beyondCoding,
.linkplusIt {
  position: absolute;
  line-height: 2rem;
}
.linkplusIt {
  top: 0;
  left: 0;
  font-weight: 300;
}
.beyondCoding {
  top: 0.31rem;
  left: 9.5rem;
  font-size: 0.8rem;
  font-weight: 200;
  display: inline-block;
  width: 7.5rem;
  height: 1.31rem;
  color: #d11616;
}
.linkplusItParent {
  position: absolute;
  top: 1.5rem;
  left: 7.81rem;
  width: 40rem;
}
.loggo1Parent {
  position: absolute;
  top: 0;
  left: 5rem;
  width: 40rem;
  height: auto;
  text-align: left;
}
.bridgingTheGap {
  margin-block-start: 0;
  margin-block-end: 15px;
}
.technologyAndPeople {
  margin: 0.5rem 0;
}
.technologyAndPeopleT{
  padding: 0.8rem 0;
  margin: 0;
}
.bridgingTheGapContainer {
  position: absolute;
  top: 11.75rem;
  left: 4.88rem;
  font-size: 2.5rem;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 40.13rem;
}
.ourInnovative {
  color: var(--color-white);
}
.withOurInnovativeContainer {
  position: absolute;
  top: 17.75rem;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  width: 49.88rem;
  color: var(--color-crimson);
}
.c662785d865245e784dcAdc885Icon {
  position: absolute;
  top: 21.25rem;
  left: 14.94rem;
  width: 19.94rem;
  height: 23.19rem;
  object-fit: cover;
}
.groupParent {
  position: absolute;
  top: 3.63rem;
  left: -0.69rem;
  width: 49.88rem;
  height: 42.94rem;
  text-align: center;
  font-size: var(--font-size-11xl);
}
.unlockingThePowerContainer {
  position: absolute;
  top: 5.13rem;
  left: 0;
  font-size:1.7rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 800;
}
.aboutUsParent,
.establishedInNovember {
  position: absolute;
  left: 0;
  color: var(--color-white);
  width: 26.81rem;
}
.aboutUsParent {
  top: 11.38rem;
  height: 13.56rem;
  font-size: var(--font-size-lg);
}
.establishedInNovember {
  top: 0;
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 200;
  display: inline-block;
}
.roadmap {
  position: relative;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 600;
}

.roadmap button {
  background-color: transparent;
  font-size: 1rem;
  border: none;
  color: white;
}
.roadmapWrapper {
  position: absolute;
  top: 31.81rem;
  left: 0;
  background-color: var(--color-gray-100);
  border: 0.5px solid var(--color-white);
  box-sizing: border-box;
  width: 26.81rem;
  height: 6.19rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-61xl);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
}
.asds1Icon,
.meee23UiuxDesignForAProduIcon {
  position: absolute;
  top: -1.56rem;
  left: 3.5rem;
  width: 18rem;
  height: auto;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon {
  top: 17.49rem;
  left: 5.92rem;
  width: 13.08rem;
  height: 11.02rem;
}
.asds1Parent,
.groupWrapper {
  position: absolute;
  width: 17.75rem;
  height: 27.01rem;
}
.asds1Parent {
  top: 0;
  left: 0;
}
.groupWrapper {
  top: 5.5rem;
  left: 27.63rem;
}
.p {
  margin-block-start: 0;
  margin-block-end: 10px;
}
.in2021Linkplus {
  margin: 0;
  font-weight: 300;
  color: var(--color-white);
}
.in2021LinkplusContainer,
.linkplusLinkplusItContainer {
  position: absolute;
  top: 0;
  line-height: 2rem;
  display: inline-block;
}
.in2021LinkplusContainer {
  left: 24.81rem;
  width: 14.06rem;
}
.linkplusLinkplusItContainer {
  left: 0;
  width: 14.88rem;
}
.in2021LinkplusItContinuedParent {
  position: absolute;
  top: 43.88rem;
  left: 2.5rem;
  width: 38.88rem;
  height: 12.63rem;
}
.in2020LinkplusContainer,
.weWereAbleContainer {
  position: absolute;
  top: 0;
  line-height: 2rem;
  display: inline-block;
}
.weWereAbleContainer {
  left: 24.81rem;
  width: 15.63rem;
}
.in2020LinkplusContainer {
  left: 0;
  width: 15.19rem;
}
.weWereAbleToAttractMoreCParent {
  position: absolute;
  top: 63.5rem;
  left: 2.5rem;
  width: 40.44rem;
  height: 16.63rem;
}
.asOurServices {
  margin-block-start: 0;
  margin-block-end: 10px;
  font-weight: 300;
  color: var(--color-white);
}
.asOurServicesContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  display: inline-block;
  width: 15.63rem;
}
.linkplusItNotOnlyContinuedParent {
  position: absolute;
  top: 85.88rem;
  left: 2.5rem;
  width: 40.44rem;
  height: 17.25rem;
}
.groupContainer {
  position: absolute;
}
.thisYearMarkedATurningPoiParent {
  position: absolute;
  top: 107.81rem;
  left: 2.5rem;
  width: 40.44rem;
  height: 12.63rem;
}
.groupContainer {
  top: 55.94rem;
  left: 1.56rem;
  width: 45.38rem;
  height: 120.44rem;
  font-size: var(--font-size-xs);
  color: var(--color-crimson);
}
.linkplusIt2 {
  position: absolute;
  top: 10.38rem;
  left: 0;
  line-height: 2rem;
  font-weight: 300;
}
.weUnderstandThat {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.weUnderstandThatContainer {
  position: absolute;
  top: 23.25rem;
  left: 5.56rem;
  font-size: var(--font-size-xl);
  line-height: 1.25rem;
  font-weight: 300;
  text-align: right;
}
.softwareDevelopment {
  color: var(--color-crimson);
}
.employsAHolisticContainer,
.methodology {
  position: absolute;
  line-height: 2rem;
  text-transform: uppercase;
}
.employsAHolisticContainer {
  top: 14.25rem;
  left: 0;
  font-size: 2rem;
}
.methodology {
  top: 0;
  left: 12.94rem;
  font-size: var(--font-size-21xl);
  font-weight: 600;
  color: var(--color-crimson);
}
.meee23UiuxDesignForAProduIcon1 {
  position: absolute;
  top: 33.5rem;
  left: 13rem;
  width: 20rem;
  height: auto;
  object-fit: cover;
}
.linkplusItGroup {
  position: absolute;
  top: 185.75rem;
  left: 1.44rem;
  width: 45.63rem;
  height: 58.38rem;
}
.endToEndSolutions {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 19.5rem;
}
.weHandleEvery {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.weHandleEveryContainer {
  position: absolute;
  top: 4.5rem;
  left: 0.13rem;
  font-size: var(--font-size-base);
  line-height: 2rem;
  font-weight: 300;
  color: var(--color-white);
  display: inline-block;
  width: 19.19rem;
}
.endToEndSolutionsParent {
  position: absolute;
  top: 31.13rem;
  left: 0;
  width: 19.5rem;
  height: 18.81rem;
}
.accessToA,
.staffAugmentation {
  position: absolute;
  line-height: 2rem;
  display: inline-block;
}
.staffAugmentation {
  top: 0;
  left: 0;
  text-transform: uppercase;
  font-weight: 600;
  width: 17.75rem;
}
.accessToA {
  top: 4.5rem;
  left: 0.13rem;
  font-size: var(--font-size-base);
  font-weight: 300;
  color: var(--color-white);
  width: 17.5rem;
}
.sadsad3Icon {
  position: absolute;
}
.staffAugmentationParent {
  position: absolute;
  top: 31.13rem;
  left: 27.13rem;
  width: 17.75rem;
  height: 12.5rem;
}
.sadsad3Icon {
  top: -1.62rem;
  left: 9.63rem;
  width: 25.38rem;
  height: 33.5rem;
  object-fit: cover;
}
.poweringYourSuccessContainer {
  position: absolute;
  top: 10.94rem;
  left: 4.69rem;
  font-size: var(--font-size-16xl);
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 35.19rem;
  color: var(--color-white);
}
.groupDiv {
  position: absolute;
  top: 253.5rem;
  left: 1.81rem;
  width: 44.88rem;
  height: 49.94rem;
  font-size: 1rem;
  color: var(--color-crimson);
}
.linkplusIt4 {
  position: absolute;
  top: 0;
  left: 0.25rem;
  line-height: 2rem;
  font-weight: 300;
}
.weHaveUsedContainer {
  position: absolute;
  top: 11.31rem;
  left: 12.69rem;
  line-height: 1.5rem;
  font-weight: 300;
  text-align: right;
}
.aiTools {
  margin: 0;
  color: var(--color-crimson);
}
.thisWebsiteWasContainer {
  position: absolute;
  top: 3.88rem;
  left: 0;
  font-size: var(--font-size-26xl);
  line-height: 2rem;
  text-transform: uppercase;
}
.caretLeftIcon {
  position: absolute;
  top: calc(50% - 2.3px);
  right: 43.16rem;
  width: 1.97rem;
  height: 1.97rem;
  overflow: hidden;
}
.meee23UiuxDesignForAProduIcon2 {
  position: absolute;
  top: 1.89rem;
  left: 0;
  width: 12.68rem;
  height: 8.33rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon3,
.meee23UiuxDesignForAProduIcon4 {
  position: absolute;
  top: 9.15rem;
  left: 1.52rem;
  width: 12.23rem;
  height: 6.65rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon4 {
  top: 3.73rem;
  left: 25.97rem;
  width: 10.95rem;
  height: 6.48rem;
}
.meee23UiuxDesignForAProduIcon5,
.meee23UiuxDesignForAProduIcon6 {
  position: absolute;
  top: 8.25rem;
  left: 28.72rem;
  width: 16.12rem;
  height: 9.52rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon6 {
  top: 7.43rem;
  left: 8rem;
  width: 13.66rem;
  height: 10.09rem;
}
.meee23UiuxDesignForAProduIcon7,
.viberImage2023031415484Icon {
  position: absolute;
  top: 1.89rem;
  left: 13.74rem;
  width: 11.49rem;
  height: 7.14rem;
  object-fit: cover;
}
.viberImage2023031415484Icon {
  top: 10.91rem;
  left: 16.66rem;
  width: 17.15rem;
  height: 5.54rem;
}
.capture61Icon,
.mmm23LaptopPhonePixarStyleIcon {
  position: absolute;
  top: 5.25rem;
  left: 31.47rem;
  width: 6.24rem;
  height: 13.5rem;
  object-fit: cover;
}
.mmm23LaptopPhonePixarStyleIcon {
  top: 2.46rem;
  left: 34.83rem;
  width: 6.69rem;
  height: 6.69rem;
}
.meee23UiuxDesignForAProduIcon8 {
  top: 0;
  left: 8.9rem;
  width: 5.46rem;
  height: 5.46rem;
}
.createMeALandingpage1,
.meee23UiuxDesignForAProduIcon8,
.meeeeYoungHappyManProgrammIcon {
  position: absolute;
  object-fit: cover;
}
.meeeeYoungHappyManProgrammIcon {
  top: 5.01rem;
  left: 22.52rem;
  width: 8.86rem;
  height: 5.91rem;
}
.createMeALandingpage1 {
  top: 6.36rem;
  left: 39.22rem;
  width: 5.91rem;
  height: 8.49rem;
}
.caretLeftParent {
  position: absolute;
  top: calc(50% + 24.5px);
  left:-1rem;
  width: 40rem;
  height: auto;
}
.linkplusItContainer {
  position: absolute;
  top: 508.94rem;
  left: 1.56rem;
  width: 45.38rem;
  height: 40.56rem;
  font-size: var(--font-size-xl);
}
.pristinaKosovo {
  position: absolute;
  top: 0;
  left: 2.94rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 14.5rem;
  height: 1.44rem;
}
.strTiranaIconContainer {
  position: absolute;
  top: 3.63rem;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  text-align: center;
}
.pristinaKosovoParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 20.31rem;
  height: 8.25rem;
}
.skopjeNorthMacedoniaContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 22.5rem;
  height: 1.44rem;
}
.borisTrajkovski1Container {
  position: absolute;
  top: 3.63rem;
  left: 0.31rem;
  font-size: var(--font-size-xl);
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  text-align: center;
  display: inline-block;
  width: 22.19rem;
}
.skopjeNorthMacedoniaParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 22.5rem;
  height: 8.25rem;
}
.emailInfolinkplusItcom {
  position: absolute;
  top: 11.25rem;
  left: 2.81rem;
  font-size: var(--font-size-xl);
  line-height: 2.1rem;
  font-weight: 300;
  color: var(--color-crimson);
}
.groupParent3 {
  position: absolute;
  top: 18.19rem;
  left: 0;
  width: 22.5rem;
  height: 13.25rem;
}
.groupFrame,
.groupWrapper1 {
  position: absolute;
  width: 22.5rem;
  height: 31.44rem;
}
.groupWrapper1 {
  top: 0;
  left: 0;
}
.groupFrame {
  top: 40.25rem;
  left: 7.06rem;
}
.iconSectionTablet{
  display: flex;
  justify-content: center;
  align-items: center;
}
.groupIcon {
  padding: 0 0.5rem;
  color: rgb(255, 3, 3);
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: 100;
}
.name {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 100;
}

.copyrightdesktop {
  font-weight: 100;
  margin: 0 5rem;
}
.groupChild {
  top: 0.25rem;
  box-sizing: border-box;
  width: 30.25rem;
  height: 3.75rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  background-color: transparent;
  color: white;
  padding-top: 2rem;
}
.groupChild:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.groupItem:focus {
  border: none;
  outline: none;
  background-color: #212121;
}

.groupInner:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.sendWrapper{
  cursor: pointer;
}
.email,
.groupChild,
.groupItem {
  position: absolute;
  left: 0;
}
.email {
  top: 7.81rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
}
.groupItem {
  top: 8.06rem;
  box-sizing: border-box;
  width: 30.25rem;
  height: 3.75rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  background-color: transparent;
  color: white;
  padding-top: 2rem;
}
.message,
.send {
  line-height: 2rem;
  text-transform: capitalize;
}
.message {
  position: absolute;
  top: 15.63rem;
  left: 0;
  font-weight: 300;
}
.send {
  position: relative;
  background-color: transparent;
  border: none;
  color: white;
  font-size: 1rem;
}
.sendWrapper {
  position: absolute;
  top: 28.19rem;
  left: 6.94rem;
  background-color: var(--color-crimson);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-81xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.groupInner,
.nameParent {
  position: absolute;
  width: 30.25rem;
}
.groupInner {
  top: 15.75rem;
  left: 0;
  box-sizing: border-box;
  height: 10.44rem;
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  padding-top: 2rem;
  color: white;
  text-decoration: none;
}
.nameParent {
  top: 0;
  left: 3.19rem;
  height: 31.44rem;
}
.linkplusItAllRightsReserveWrapper {
  position: absolute;
  top: 0;
  left: -10rem;
  width: 100%;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.groupIcon {
  position: absolute;
  height: 56.25%;
  width: 25.88%;
  top: 21.88%;
  right: 74.12%;
  bottom: 21.88%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
}
.groupIcons {
  padding: 0 0.5rem;
  color: rgb(255, 3, 3);
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: 100;
}
.groupParent1,
.groupParent4 {
  position: absolute;
  width:100%;
}
.groupParent4 {
  top: 83.31rem;
  left: 0;
  height: 2rem;
  font-size: var(--font-size-base);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.groupParent1 {
  top: 558.88rem;
  left: 5.94rem;
  height: 85.31rem;
  font-size: var(--font-size-6xl);
}
.dsjfkdsjf1Icon {
  position: absolute;
  top: -1.37rem;
  left: -1.56rem;
  width: 19.69rem;
  height: 20.56rem;
  object-fit: cover;
}
.experienceThePower {
  position: relative;
  text-decoration: none;
  color: white;
  cursor: pointer;
  padding: 1rem 6rem;
}
.advanceYourCareerWithCompeWrapper,
.experienceTheBestOfBothWoWrapper {
  position: absolute;
  left: 0.06rem;
  border-bottom: 0.1px solid var(--color-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.experienceThePowerOfTeamCWrapper {
  top: 28.06rem;
}
.experienceThePowerOfTeamCWrapper,
.joinAFlexibleForwardThinkWrapper {
  position: absolute;
  left: 0.06rem;
  border-bottom: 0.1px solid var(--color-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.advanceYourCareerWithCompeWrapper {
  top: 43.44rem;
}
.joinAFlexibleForwardThinkWrapper {
  top: 33.19rem;
}
.experienceTheBestOfBothWoWrapper {
  top: 38.31rem;
}
.joinTheTeamContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 1rem;
  text-transform: uppercase;
  display: inline-block;
  width: 28.75rem;
}
.joinUsNowWrapper {
  position: absolute;
  top: 10rem;
  left: 9.81rem;
  border: 1px solid var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
}
.joinTheTeamRevolutionizingParent {
  position: absolute;
  top: 0;
  left: 16.38rem;
  width: 28.75rem;
  height: 17.31rem;
  text-align: right;
  font-size: var(--font-size-16xl);
}
.dsjfkdsjf1Parent {
  position: absolute;
  top: 452.88rem;
  left: 1.56rem;
  width: 45.13rem;
  height: 46.69rem;
}
.majkmSimplySectionWebsiteSIcon,
.sds1Icon {
  position: absolute;
  object-fit: cover;
}
.sds1Icon {
  top: -2.87rem;
  left: -3.12rem;
  width: 21rem;
  height: 21.36rem;
}
.majkmSimplySectionWebsiteSIcon {
  top: 8.88rem;
  left: 7.43rem;
  width: 14.14rem;
  height: 10.75rem;
}
.groupWrapper2,
.sds1Parent {
  position: absolute;
  width: 19.83rem;
  height: 16.88rem;
}
.sds1Parent {
  top: 0;
  left: 0;
}
.groupWrapper2 {
  top: 7.44rem;
  left: 25.49rem;
}
.javaEeSpring,
.technologies {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.technologies {
  top: 4.63rem;
  left: 1.5rem;
  width: 9.44rem;
}
.javaEeSpring {
  top: 7.25rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 12.38rem;
}
.rectangleDiv {
  position: absolute;
  top: 0;
  left: 4.81rem;
  background: radial-gradient(50% 50%at 50% 50%, #ed4d4d, #d11616);
  width: 2.81rem;
  height: 2.81rem;
}
.groupWrapper3,
.technologiesParent {
  position: absolute;
  width: 12.38rem;
  height: 15.25rem;
}
.technologiesParent {
  top: 0;
  left: 0;
}
.groupWrapper3 {
  top: 35.5rem;
  left: 0.19rem;
}
.businessIntelligence,
.dataAnalysisreportBuilding {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.businessIntelligence {
  top: 4.56rem;
  left: 0;
  width: 14.31rem;
}
.dataAnalysisreportBuilding {
  top: 8.25rem;
  left: 1.13rem;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 12.13rem;
}
.groupChild1 {
  position: absolute;
  top: 0;
  left: 5.75rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.businessIntelligenceParent,
.groupWrapper4 {
  position: absolute;
  width: 14.31rem;
  height: 12.25rem;
}
.businessIntelligenceParent {
  top: 0;
  left: 0;
}
.groupWrapper4 {
  top: 58.56rem;
  left: 0.19rem;
}
.databases,
.mysqlMongodbPostgresql {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.databases {
  top: 4.63rem;
  left: 1.06rem;
  width: 7.13rem;
}
.mysqlMongodbPostgresql {
  top: 7.25rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 9.19rem;
}
.groupChild2 {
  position: absolute;
  top: 0;
  left: 3.19rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.databasesParent,
.groupWrapper5 {
  position: absolute;
  width: 9.19rem;
  height: 11.25rem;
}
.databasesParent {
  top: 0;
  left: 0;
}
.groupWrapper5 {
  top: 35.5rem;
  left: 18.25rem;
}
.mobile,
.reactNativeKotlin {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.mobile {
  top: 0;
  left: 2.81rem;
  width: 4.69rem;
}
.reactNativeKotlin {
  top: 2.63rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 10.31rem;
}
.groupWrapper7,
.mobileParent {
  position: absolute;
  left: 0;
  width: 10.31rem;
  height: 6.63rem;
}
.mobileParent {
  top: 0;
}
.groupWrapper7 {
  top: 4.56rem;
}
.groupChild3 {
  position: absolute;
  top: 0;
  left: 3.75rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent6 {
  position: absolute;
  top: 58.56rem;
  left: 17.69rem;
  width: 10.31rem;
  height: 11.19rem;
}
.qualityAssurance,
.seleniumGherkinManual {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.qualityAssurance {
  top: 0;
  left: 0.81rem;
  width: 12.5rem;
}
.seleniumGherkinManual {
  top: 2.63rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  width: 14.13rem;
}
.groupWrapper8,
.qualityAssuranceParent {
  position: absolute;
  left: 0;
  width: 14.13rem;
  height: 6.63rem;
}
.qualityAssuranceParent {
  top: 0;
}
.groupWrapper8 {
  top: 5.13rem;
}
.groupChild4 {
  position: absolute;
  top: 0;
  left: 5.63rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent8 {
  position: absolute;
  top: 0;
  left: 0.38rem;
  width: 14.13rem;
  height: 11.75rem;
}
.agileScrum,
.projectManagement {
  position: absolute;
  line-height: 2rem;
  display: inline-block;
}
.projectManagement {
  top: 0;
  left: 0;
  text-transform: uppercase;
  width: 14.5rem;
}
.agileScrum {
  top: 2.63rem;
  left: 2.88rem;
  font-size: var(--font-size-base);
  text-transform: capitalize;
  font-weight: 200;
  text-align: center;
  width: 8.81rem;
}
.groupWrapper9,
.projectManagementParent {
  position: absolute;
  left: 0;
  width: 14.5rem;
  height: 6.63rem;
}
.projectManagementParent {
  top: 0;
}
.groupWrapper9 {
  top: 4.56rem;
}
.groupChild5 {
  position: absolute;
  top: 0;
  left: 5.88rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent7,
.groupParent9 {
  position: absolute;
  width: 14.5rem;
}
.groupParent9 {
  top: 23.56rem;
  left: 0;
  height: 11.19rem;
  text-align: left;
}
.groupParent7 {
  top: 35rem;
  left: 31.38rem;
  height: 34.75rem;
  text-align: center;
}
.technology {
  top: 0;
  line-height: 0.63rem;
  text-transform: uppercase;
  font-weight: 300;
  width: 10.63rem;
}
.ourExpertiseLies,
.technology,
.whatWeUse {
  position: absolute;
  left: 0;
  display: inline-block;
}
.whatWeUse {
  top: 3.75rem;
  font-size: var(--font-size-16xl);
  line-height: 0.63rem;
  text-transform: uppercase;
  width: 32.06rem;
}
.ourExpertiseLies {
  top: 7.5rem;
  font-size: var(--font-size-base);
  line-height: 2rem;
  font-weight: 300;
  width: 19.56rem;
}
.groupWrapper11,
.technologyParent {
  position: absolute;
  width: 32.06rem;
  height: 15.5rem;
}
.technologyParent {
  top: 0;
  left: 0;
}
.groupWrapper11 {
  top: 8.13rem;
  left: 6.81rem;
  font-size: var(--font-size-lg);
}
.stack,
.technology1,
.weUse {
  position: absolute;
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.technology1 {
  top: 0;
  left: 2rem;
  width: 9.19rem;
}
.stack {
  top: 15.44rem;
  left: 2.5rem;
  width: 4.69rem;
}
.weUse {
  top: 26.38rem;
  left: 2.95rem;
  width: 5.38rem;
}
.technologyGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 2.95rem;
  height: 31.75rem;
  font-size: var(--font-size-base);
}
.groupParent5 {
  position: absolute;
  top: 312.81rem;
  left: 1.31rem;
  width: 45.88rem;
  height: 70.81rem;
  font-size: var(--font-size-xl);
}
.highlights {
  color: var(--color-gold);
}
.highlightsOfOurContainer {
  position: absolute;
  top: 5.81rem;
  left: 28.44rem;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 16.94rem;
}
.groupChild6 {
  position: absolute;
  top: calc(50% + 400px);
  right: 23.75rem;
  width: 11.88rem;
  height: 3rem;
}
.groupChild20 {
  position: absolute;
  top: calc(50% + 400px);
  right: 10.75rem;
  width: 11.88rem;
  height: 3rem;
}

.groupChild7 {
  top: 0;
  background-color: var(--color-gold);
  height: 15.88rem;
}
.groupChild7,
.groupChild8,
.rectangleParent {
  position: absolute;
  left: 0;
  width: 1rem;
}
.groupChild8 {
  top: 2rem;
  box-sizing: border-box;
  height: 15.88rem;
}
.rectangleParent {
  top: 28.25rem;
  height: 17.88rem;
}
.groupChild9 {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-black);
  width: 13rem;
  height: 15.88rem;
}
.taxiLimousine {
  position: absolute;
  top: 4.94rem;
  left: 1.94rem;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 9.06rem;
}
.rectangleContainer,
.rectangleGroup {
  position: absolute;
  top: 0;
  width: 13rem;
  height: 15.88rem;
}
.rectangleGroup {
  left: 0;
}
.rectangleContainer {
  left: 28.13rem;
}
.helpDeskApp {
  position: absolute;
  top: 6.94rem;
  left: 1.94rem;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 9.06rem;
}
.rectangleParent1 {
  position: absolute;
  top: 0;
  left: 14.06rem;
  width: 13rem;
  height: 15.88rem;
  font-size: var(--font-size-xl);
}

.groupWrapper12 {
  position: absolute;
  width: 41.13rem;
  height: 15.88rem;
}
.groupParent10 {
  top: 0;
  left: 0;
  width: 40rem;
  height: 13.06rem;
}
.groupWrapper12 {
  top: 24rem;
  left: 4rem;
  text-align: center;
  font-size: var(--font-size-lg);
}
.meee23UiuxDesignForAProduIcon9 {
  position: absolute;
  top: 6.02rem;
  left: 2.21rem;
  width: 20.83rem;
  height: 13.98rem;
  object-fit: cover;
}
.mee123ACloseShotOfAYoungIcon {
  object-fit: cover;
}
.groupWrapper14,
.mee123ACloseShotOfAYoungIcon,
.mee123ACloseShotOfAYoungWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 14.28rem;
  height: 14.28rem;
}
.mee123ACloseShotOfAYoungWrapper {
  box-shadow: 0 4px 100px #000;
}
.groupWrapper14 {
  box-shadow: 0 4px 50px #000;
}
.groupWrapper13,
.meee23UiuxDesignForAProduParent {
  position: absolute;
  top: 0;
  width: 21.47rem;
  height: 18.19rem;
}
.meee23UiuxDesignForAProduParent {
  left: 0;
}
.groupWrapper13 {
  left: 0.25rem;
}
.highlightsOfOurProjectsParent {
  position: absolute;
  top: 393rem;
  left: 1.56rem;
  width: 45.38rem;
  height: 50.5rem;
  font-size: var(--font-size-16xl);
}
.tablet {
  background-color: var(--color-gray-200);
  width: 100vw;
  height: 647.69rem;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-lg);
  color: var(--color-white);
  font-family: var(--font-poppins);
}
.tabletResponsive {
  position: relative;
}

@media only screen and (max-width: 1240px) and (min-width: 1120px) {
  .tabletResponsive {
    margin-left: 20%;
  }
}
@media only screen and (max-width: 1110px) and (min-width: 900px) {
  .tabletResponsive {
    margin-left: 15%;
  }
}

@media only screen and (max-width: 890px) and (min-width: 840px) {
  .tabletResponsive {
    margin-left: 5%;
  }
}

@media only screen and (max-width: 840px) and (min-width: 800px) {
  .tabletResponsive {
    margin-left: 5%;
  }
}

.sliderDivTablet {
  background-color: black;
  max-width: 10rem;
  height: 23rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  word-break: break-word;
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding: 1rem;
}

.sliderDivTablet h2 {
  font-family: "Poppins";
  font-size: 0.9rem;
  font-family: 100;
}
.sliderDivTablet p {
  font-weight: 100;
  font-size: 0.7rem;
}

/*@media screen and (min-width: 983px) {*/
/*  .responsiveMobile {*/
/*    position: relative;*/
/*    margin:20%;*/
/*  }*/
/*}*/

/*@media screen and (min-width: 900px) {*/
/*  .responsiveMobile {*/
/*    position: relative;*/
/*    margin:10%;*/
/*  }*/
/*}*/