@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;600;700;800&display=swap");
body {
  margin: 0 !important;
  line-height: normal;
  background-color: #0e0e0e;
  overflow-x: hidden;
}

:root {
  /* fonts */
  --font-poppins: Poppins;

  /* font sizes */
  --font-size-base: 1rem;
  --font-size-sm: 0.88rem;
  --font-size-lg: 1.13rem;
  --font-size-3xs: 0.63rem;
  --font-size-6xl: 1.56rem;
  --font-size-11xl: 1.88rem;
  --font-size-3xl: 1.38rem;
  --font-size-xl: 1.25rem;
  --font-size-16xl: 2.19rem;
  --font-size-mini: 0.94rem;
  --font-size-26xl: 2.81rem;
  --font-size-21xl: 2.5rem;
  --font-size-xs: 0.75rem;
  --font-size-46xl: 4.06rem;

  /* Colors */
  --color-gray-100: #0e0e0e;
  --color-gray-200: #0d0d0d;
  --color-white: #fff;
  --color-gainsboro: #d9d9d9;
  --color-crimson: #d11616;
  --color-black: #000;
  --color-gold: #fad71b;

  /* Paddings */
  --padding-3xs: 0.63rem;
  --padding-81xl: 6.25rem;
  --padding-61xl: 5rem;
  --padding-mini: 0.94rem;
}
