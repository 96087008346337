.highlights {
  color: var(--color-gold);
}
.highlightsOf {
  margin-block-start: 0;
  margin-block-end: 15px;
}
.ourProjects {
  margin: 0;
}
.highlightsOfOurContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 28.31rem;
}
.highlightsOfOurProjectsWrapper {
  position: absolute;
  top: 11rem;
  left: 40.94rem;
  width: 28.31rem;
  height: 7.88rem;
  text-align: left;
  font-size: 3.75rem;
}
.caretLeftIcon,
.caretRightIcon {
  position: absolute;
  top: calc(50% + 489.5px);
  right: 37.69rem;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}
.caretRightIcon {
  right: 28.81rem;
}
.groupChild {
  top: 0;
  background-color: var(--color-gold);
  height: 15.88rem;
}
.groupChild,
.groupItem,
.rectangleParent {
  position: absolute;
  left: 0;
  width: 3rem;
}
.groupItem {
  top: 2rem;
  box-sizing: border-box;
  height: 15.88rem;
}
.rectangleParent {
  top: 45.31rem;
  height: 17.88rem;
}
.groupInner {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-black);
  width: 20rem;
  height: 15.88rem;
}
.taxiLimousine {
  position: absolute;
  top: 5.94rem;
  left: 3rem;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 13.94rem;
}
.groupWrapper,
.rectangleGroup {
  position: absolute;
  width: 60rem;
  height: 15.88rem;
}
.rectangleGroup {
  top: 0;
  left: 0;
}
.groupWrapper {
  top: 40rem;
  left: 7rem;
}
.helpDeskApp {
  position: absolute;
  top: 6.94rem;
  left: 0.63rem;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
  width: 18.84rem;
}
.groupContainer,
.groupDiv {
  position: absolute;
  top: 45.31rem;
  left: 27.75rem;
  width: 20rem;
  height: 15.88rem;
}
.groupDiv {
  left: 49.5rem;
}
.meee23UiuxDesignForAProduIcon {
  position: absolute;
  top: 10.73rem;
  left: 4.63rem;
  width: 32.21rem;
  height: 20.96rem;
  object-fit: cover;
}
.mee123ACloseShotOfAYoungIcon {
  position: absolute;
  top: 0;
  left: 0;
  width: 23.46rem;
  height: 23.46rem;
  object-fit: cover;
}
.groupWrapper1,
.mee123ACloseShotOfAYoungWrapper {
  position: absolute;
  top: 0;
  left: 0;
  box-shadow: 0 4px 100px #000;
  width: 23.46rem;
  height: 23.46rem;
}
.groupWrapper1 {
  box-shadow: 0 4px 50px #000;
}
.groupFrame,
.meee23UiuxDesignForAProduParent {
  position: absolute;
  top: 0;
  width: 35.27rem;
  height: 29.88rem;
}
.meee23UiuxDesignForAProduParent {
  left: 0;
}
.groupFrame {
  left: 0.06rem;
}
.desktopInner,
.groupParent {
  position: absolute;
  width: 69.5rem;
  height: 67.19rem;
}
.groupParent {
  top: 0;
  left: 0;
}
.desktopInner {
  top: 409rem;
  left: 10.25rem;
  text-align: center;
}
.joinTheTeam {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.industries {
  margin: 0;
  color: var(--color-crimson);
}
.joinTheTeamContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 28.75rem;
}
.joinUsNow {
  position: relative;
  line-height: 2rem;
  font-weight: 300;
  text-decoration: none;
  color: white;
}
.joinUsNowBTN {
  text-decoration: none;
  color: white;
  cursor: pointer;
  padding: 1rem 6rem;
}
.joinUsNowWrapper {
  position: absolute;
  top: 13.44rem;
  left: 9.81rem;
  border: 1px solid var(--color-white);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-base);
}
.joinTheTeamRevolutionizingParent {
  position: absolute;
  top: 0;
  left: 41rem;
  width: 28.75rem;
  height: 17.31rem;
  text-align: right;
  font-size: 3.13rem;
}
.advanceYourCareerWithCompeWrapper,
.experienceTheBestOfBothWoWrapper,
.experienceThePowerOfTeamCWrapper,
.joinAFlexibleForwardThinkWrapper {
  position: absolute;
  top: 28.31rem;
  left: 0;
  border-bottom: 0.1px solid var(--color-white);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs);
  align-items: flex-start;
  justify-content: flex-start;
}
.advanceYourCareerWithCompeWrapper,
.experienceTheBestOfBothWoWrapper,
.joinAFlexibleForwardThinkWrapper {
  top: 47.44rem;
}
.experienceTheBestOfBothWoWrapper,
.joinAFlexibleForwardThinkWrapper {
  top: 34.69rem;
}
.experienceTheBestOfBothWoWrapper {
  top: 41.06rem;
}
.groupParent2 {
  position: absolute;
  top: 0.69rem;
  left: 0;
  width: 69.75rem;
  height: 50.69rem;
}
.dsjfkdsjf1Icon {
  position: absolute;
  top: -2.25rem;
  left: -2.37rem;
  width: 23.81rem;
  height: 23.75rem;
  object-fit: cover;
}
.groupParent1,
.sadsad3Icon {
  position: absolute;
  top: 491.81rem;
  left: 10.13rem;
  width: 69.75rem;
  height: 51.38rem;
}
.sadsad3Icon {
  top: 0.13rem;
  left: 36.06rem;
  width: 31.56rem;
  height: 42rem;
  object-fit: cover;
}
.linkplusIt {
  color: var(--color-crimson);
}
.poweringYourSuccessContainer {
  position: absolute;
  top: 16.94rem;
  left: 34.25rem;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 35.19rem;
}
.endToEndSolutions,
.weHandleEveryContainer {
  position: absolute;
  left: 0;
  line-height: 2rem;
  display: inline-block;
}
.endToEndSolutions {
  top: 0;
  text-transform: uppercase;
  font-weight: 600;
  width: 20.74rem;
}
.weHandleEveryContainer {
  top: 4.5rem;
  font-size: var(--font-size-xl);
  font-weight: 300;
  color: var(--color-white);
  width: 28.19rem;
}
.endToEndSolutionsParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 28.19rem;
  height: 18.81rem;
}
.staffAugmentation {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
}
.staffAugmentationParent {
  position: absolute;
  top: 31.19rem;
  left: 0;
  width: 28.19rem;
  height: 10.5rem;
}
.groupParent3 {
  position: absolute;
  top: 0;
  left: 0;
  width: 28.19rem;
  height: 41.69rem;
  font-size: var(--font-size-6xl);
  color: var(--color-crimson);
}
.meee23UiuxDesignForAProdu,
.sadsad3Parent {
  position: absolute;
  top: 4rem;
  left: 37.81rem;
  width: 32rem;
  height: 25.25rem;
}
.sadsad3Parent {
  top: 246.75rem;
  left: 10.13rem;
  width: 69.81rem;
  height: 41.69rem;
  font-size: var(--font-size-16xl);
}
.bridgingTheGap {
  margin-block-start: 0;
  margin-block-end: 40px;
}
.bridgingTheGapContainer {
  position: absolute;
  top: 12.25rem;
  left: 0.5rem;
  font-size:3.2rem;
  line-height: 2rem;
  text-transform: uppercase;
  display: inline-block;
  width: 68.31rem;
}
.ourInnovative {
  color: var(--color-white);
}
.withOurInnovativeContainer {
  position: absolute;
  top: 21.25rem;
  left: 9.75rem;
  line-height: 2rem;
  font-weight: 200;
  display: inline-block;
  width: 49.88rem;
  color: var(--color-crimson);
}
.c662785d865245e784dcAdc885Icon {
  position: absolute;
  top: 24.75rem;
  left: 23rem;
  width: 23.33rem;
  height: 27.25rem;
  object-fit: cover;
}
.loggo1Icon {
  position: absolute;
  top: 0;
  left: 2rem;
  width: 5.5rem;
  height: auto;
  object-fit: cover;
}
.beyondCoding {
  font-size: 0.8rem;
  font-weight: 200;
  color: #d11616;
}
.linkplusItParent {
  position: absolute;
  top: 1.5rem;
  left: 7.81rem;
  width: 17rem;
  height: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.loggo1Parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 25rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.bridgingTheGapBetweenTechnParent {
  position: absolute;
  top: 5.25rem;
  left: 10.63rem;
  width: 68.81rem;
  height: 50.5rem;
  text-align: center;
  font-size: var(--font-size-11xl);
}
.aboutUs {
  font-size: 1.7rem;
  font-weight: 300;
}
.unlockingThePowerContainer {
  position: absolute;
  top: 5.13rem;
  left: 0;
  font-size: var(--font-size-16xl);
  line-height: 2.19rem;
  text-transform: uppercase;
  font-weight: 800;
}
.aboutUsParent,
.establishedInNovember {
  position: absolute;
  left: 0;
  color: var(--color-white);
  width: 26.81rem;
}
.aboutUsParent {
  top: 11.38rem;
  height: 13.56rem;
  font-size: var(--font-size-lg);
}
.establishedInNovember {
  top: 0;
  font-size: var(--font-size-xl);
  line-height: 2rem;
  font-weight: 200;
  display: inline-block;
}
.p {
  margin-block-start: 0;
  margin-block-end: 10px;
}
.thisYearMarked {
  margin: 0;
  font-weight: 300;
  color: var(--color-white);
  font-size: 14px;
}
.thisYearMarkedContainer {
  position: absolute;
  top: 67.94rem;
  left: 53.81rem;
  line-height: 2rem;
  display: inline-block;
  width: 15.63rem;
}
.linkplusItContinued {
  margin-block-start: 0;
  margin-block-end: 10px;
  font-weight: 300;
  color: var(--color-white);
  font-size: 14px;
}
.in2021LinkplusContainer,
.linkplusItContinuedContainer,
.linkplusItNotContainer,
.weWereAbleContainer {
  position: absolute;
  top: 67.94rem;
  left: 35.88rem;
  line-height: 2rem;
  display: inline-block;
  width: 15.63rem;
}
.in2021LinkplusContainer,
.linkplusItNotContainer,
.weWereAbleContainer {
  left: 17.94rem;
}
.in2021LinkplusContainer,
.weWereAbleContainer {
  top: 47.88rem;
  left: 53.81rem;
  font-size: var(--font-size-sm);
}
.in2021LinkplusContainer {
  left: 17.94rem;
  width: 14.06rem;
}
.asOurServicesContainer,
.linkplusLinkplusItContainer {
  position: absolute;
  left: 0;
  line-height: 2rem;
  display: inline-block;
}
.linkplusLinkplusItContainer {
  top: 47.88rem;
  font-size: var(--font-size-sm);
  width: 14.88rem;
}
.asOurServicesContainer {
  top: 67.94rem;
  width: 15.63rem;
}
.roadmap {
  position: relative;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 600;
}
.roadmap button {
  background-color: transparent;
  font-size: 2rem;
  color: white;
  border: none;
}
.roadmapWrapper {
  position: absolute;
  top: 31.81rem;
  left: 0;
  background-color: var(--color-gray-100);
  border: 0.5px solid var(--color-white);
  box-sizing: border-box;
  width: 26.81rem;
  height: 6.19rem;
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-61xl);
  align-items: center;
  justify-content: center;
  font-size: var(--font-size-6xl);
  color: var(--color-white);
}
.asds1Icon,
.meee23UiuxDesignForAProduIcon1 {
  position: absolute;
  top: -1.56rem;
  left: 3.5rem;
  width: 25rem;
  height: auto;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon1 {
  top: 25.37rem;
  left: 8.97rem;
  width: 17.59rem;
  height: 14.64rem;
}
.asds1Parent,
.groupWrapper2 {
  position: absolute;
  top: 0;
  width: 25.31rem;
  height: 38.51rem;
}
.asds1Parent {
  left: 0;
}
.groupWrapper2 {
  left: 44.06rem;
}
.groupParent5 {
  position: absolute;
  top: 0;
  left: 0;
  width: 69.44rem;
  height: 85.19rem;
}
.in2020LinkplusContainer {
  position: absolute;
  top: 47.88rem;
  left: 35.88rem;
  font-size: var(--font-size-sm);
  line-height: 2rem;
  display: inline-block;
  width: 15.19rem;
}
.groupParent4 {
  position: absolute;
  top: 71.38rem;
  left: 10.31rem;
  width: 69.44rem;
  height: 85.19rem;
  font-size: var(--font-size-xs);
  color: var(--color-crimson);
}
.weUnderstandThat {
  margin-block-start: 0;
  margin-block-end: 20px;
}
.weUnderstandThatContainer {
  position: absolute;
  top: 16.75rem;
  left: 30.19rem;
  font-size: var(--font-size-xl);
  line-height: 1.25rem;
  font-weight: 300;
  text-align: right;
}
.employsAHolistic {
  margin-block-start: 0;
  margin-block-end: 50px;
}
.employsAHolisticContainer {
  position: absolute;
  top: 3.88rem;
  left: 0;
  font-size: var(--font-size-46xl);
  line-height: 2rem;
  text-transform: uppercase;
}
.linkplusItGroup {
  position: absolute;
  top: 0;
  left: 0;
  width: 69.81rem;
  height: 20.5rem;
}
.meee23UiuxDesignForAProduIcon2 {
  position: absolute;
  top: 23.69rem;
  left: 23.69rem;
  width: 24.88rem;
  height: 24.88rem;
  object-fit: cover;
}
.groupParent7 {
  position: absolute;
  top: 10.38rem;
  left: 0;
  width: 69.81rem;
  height: 48.56rem;
}
.methodology {
  position: absolute;
  top: 0;
  left: 25.06rem;
  font-size: var(--font-size-21xl);
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-crimson);
}
.groupParent6 {
  position: absolute;
  top: 172.19rem;
  left: 10.13rem;
  width: 69.81rem;
  height: 58.94rem;
  font-size: var(--font-size-lg);
}
.technology {
  top: 0;
  text-transform: uppercase;
  font-weight: 300;
  width: 10.63rem;
}
.ourExpertiseLies,
.technology,
.whatWeUse {
  position: absolute;
  left: 0;
  line-height: 2rem;
  display: inline-block;
}
.whatWeUse {
  top: 6rem;
  font-size: var(--font-size-46xl);
  text-transform: uppercase;
  width: 32.06rem;
}
.ourExpertiseLies {
  top: 12rem;
  font-size: var(--font-size-xl);
  font-weight: 300;
  width: 31.62rem;
}
.groupWrapper3,
.technologyParent {
  position: absolute;
  width: 32.06rem;
  height: 18rem;
}
.technologyParent {
  top: 0;
  left: 0;
}
.groupWrapper3 {
  top: 13.13rem;
  left: 6.06rem;
  font-size: var(--font-size-lg);
}
.stack,
.technology1,
.weUse {
  position: absolute;
  line-height: 2rem;
  font-weight: 300;
  display: inline-block;
  transform: rotate(90deg);
  transform-origin: 0 0;
}
.technology1 {
  top: 0;
  left: 2rem;
  width: 9.19rem;
}
.stack,
.weUse {
  top: 21.68rem;
  left: 2.5rem;
  width: 4.69rem;
}
.weUse {
  top: 38.86rem;
  left: 2.95rem;
  width: 5.38rem;
}
.technologyGroup {
  position: absolute;
  top: 0;
  left: 0.13rem;
  width: 2.95rem;
  height: 44.24rem;
}
.javaEeSpring,
.technologies {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.technologies {
  top: 5.13rem;
  left: 1.5rem;
  width: 9.44rem;
}
.javaEeSpring {
  top: 7.75rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 12.38rem;
}
.groupChild2 {
  position: absolute;
  top: 0;
  left: 4.81rem;
  background: radial-gradient(50% 50%at 50% 50%, #ed4d4d, #d11616);
  width: 2.81rem;
  height: 2.81rem;
}
.technologiesParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 12.38rem;
  height: 15.75rem;
}
.qualityAssurance,
.seleniumGherkinManual {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.qualityAssurance {
  top: 0;
  left: 0.81rem;
  width: 12.5rem;
}
.seleniumGherkinManual {
  top: 2.63rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  width: 14.13rem;
}
.groupWrapper5,
.qualityAssuranceParent {
  position: absolute;
  left: 0;
  width: 14.13rem;
  height: 6.63rem;
}
.qualityAssuranceParent {
  top: 0;
}
.groupWrapper5 {
  top: 5.13rem;
}
.groupChild3 {
  position: absolute;
  top: 0;
  left: 5.63rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent10 {
  position: absolute;
  top: 0;
  left: 55.63rem;
  width: 14.13rem;
  height: 11.75rem;
  text-align: center;
}
.businessIntelligence,
.dataAnalysisreportBuilding {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.businessIntelligence {
  top: 5.13rem;
  left: 0;
  width: 14.31rem;
}
.dataAnalysisreportBuilding {
  top: 7.75rem;
  left: 1.13rem;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 12.13rem;
}
.groupChild4 {
  position: absolute;
  top: 0;
  left: 5.75rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.businessIntelligenceParent,
.groupWrapper6 {
  position: absolute;
  left: 0;
  width: 14.31rem;
  height: 11.75rem;
}
.businessIntelligenceParent {
  top: 0;
}
.groupWrapper6 {
  top: 23.56rem;
}
.agileScrum,
.projectManagement {
  position: absolute;
  line-height: 2rem;
  display: inline-block;
}
.projectManagement {
  top: 0;
  left: 0;
  text-transform: uppercase;
  width: 14.5rem;
}
.agileScrum {
  top: 2.63rem;
  left: 2.88rem;
  font-size: var(--font-size-base);
  text-transform: capitalize;
  font-weight: 200;
  text-align: center;
  width: 8.81rem;
}
.groupWrapper7,
.projectManagementParent {
  position: absolute;
  left: 0;
  width: 14.5rem;
  height: 6.63rem;
}
.projectManagementParent {
  top: 0;
}
.groupWrapper7 {
  top: 4.56rem;
}
.groupChild5 {
  position: absolute;
  top: 0;
  left: 5.88rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent11 {
  position: absolute;
  top: 23.56rem;
  left: 55.25rem;
  width: 14.5rem;
  height: 11.19rem;
}
.groupParent9 {
  position: absolute;
  top: 54rem;
  left: 0;
  width: 69.75rem;
  height: 35.31rem;
}
.majkmSimplySectionWebsiteSIcon,
.sds1Icon {
  position: absolute;
  top: -2.87rem;
  left: -3.12rem;
  width: 28.76rem;
  height: 29.31rem;
  object-fit: cover;
}
.majkmSimplySectionWebsiteSIcon {
  top: 14.73rem;
  left: 12.66rem;
  width: 20.11rem;
  height: 13.77rem;
}
.groupWrapper8,
.sds1Parent {
  position: absolute;
  width: 30.26rem;
  height: 25.75rem;
}
.sds1Parent {
  top: 0;
  left: 0;
}
.groupWrapper8 {
  top: 9.25rem;
  left: 39.24rem;
}
.databases,
.mysqlMongodbPostgresql {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.databases {
  top: 5.13rem;
  left: 1.06rem;
  width: 7.13rem;
}
.mysqlMongodbPostgresql {
  top: 7.75rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 9.19rem;
}
.groupChild6 {
  position: absolute;
  top: 0;
  left: 3.19rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.databasesParent,
.groupWrapper9 {
  position: absolute;
  width: 9.19rem;
  height: 11.75rem;
}
.databasesParent {
  top: 0;
  left: 0;
}
.groupWrapper9 {
  top: 54rem;
  left: 30.25rem;
}
.mobile,
.reactNativeKotlin {
  position: absolute;
  line-height: 2rem;
  text-transform: capitalize;
  display: inline-block;
}
.mobile {
  top: 0;
  left: 2.81rem;
  width: 4.69rem;
}
.reactNativeKotlin {
  top: 2.63rem;
  left: 0;
  font-size: var(--font-size-base);
  font-weight: 200;
  text-align: center;
  width: 10.31rem;
}
.groupWrapper11,
.mobileParent {
  position: absolute;
  left: 0;
  width: 10.31rem;
  height: 6.63rem;
}
.mobileParent {
  top: 0;
}
.groupWrapper11 {
  top: 5.13rem;
}
.groupChild7 {
  position: absolute;
  top: 0;
  left: 3.75rem;
  background: radial-gradient(50% 50%at 50% 50%, #fff, #d1d1d1);
  width: 2.81rem;
  height: 2.81rem;
}
.groupParent12 {
  position: absolute;
  top: 77.56rem;
  left: 29.69rem;
  width: 10.31rem;
  height: 11.75rem;
}
.desktopChild,
.groupParent8 {
  position: absolute;
  width: 69.75rem;
  height: 89.31rem;
}
.groupParent8 {
  top: 0;
  left: 0;
}
.desktopChild {
  top: 304.06rem;
  left: 10.13rem;
}
.pristinaKosovo,
.strTiranaIconContainer {
  position: absolute;
  left: 0;
  line-height: 2rem;
}
.pristinaKosovo {
  top: 0;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 14.5rem;
  height: 1.44rem;
}
.strTiranaIconContainer {
  top: 3.63rem;
  font-size: var(--font-size-xl);
  text-transform: capitalize;
  font-weight: 300;
}
.pristinaKosovoParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 20.31rem;
  height: 8.25rem;
}
.skopjeNorthMacedoniaContainer {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  display: inline-block;
  width: 22.75rem;
  height: 1.44rem;
}
.skopjeNorthMacedoniaParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 22.75rem;
  height: 8.25rem;
}
.emailInfolinkplusItcom {
  position: absolute;
  top: 11.25rem;
  left: 0;
  font-size: var(--font-size-xl);
  line-height: 2rem;
  font-weight: 300;
  color: var(--color-crimson);
}
.groupParent15 {
  position: absolute;
  top: 18.19rem;
  left: 0;
  width: 22.75rem;
  height: 13.25rem;
}
.groupParent14,
.name {
  position: absolute;
  top: 0;
  left: 0;
}
.groupParent14 {
  width: 22.75rem;
  height: 31.44rem;
}
.name {
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
  z-index: 100;
  color: white;
}
.groupChild8 {
  top: 0.25rem;
  width: 30.25rem;
  height: 3.75rem;
  background-color: transparent;
  color: white;
  padding-top: 1rem;
  font-size: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
}
.groupChild8:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.groupChild9:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.groupChild10:focus {
  border: none;
  outline: none;
  background-color: #212121;
}
.email,
.Field,
.message {
  font-weight: 100;
  font-size: 1.2rem;
}
.email,
.groupChild8,
.groupChild9 {
  position: absolute;
  left: 0;
}
.email {
  top: 7.81rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 300;
}
.groupChild9 {
  top: 8.06rem;
  box-sizing: border-box;
  width: 30.25rem;
  height: 3.75rem;
  background-color: transparent;
  color: white;
  padding-top: 1rem;
  font-size: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
}
.message,
.send {
  line-height: 2rem;
  text-transform: capitalize;
}
.message {
  position: absolute;
  top: 15.63rem;
  left: 0;
  font-weight: 300;
}
.send {
  position: relative;
  background-color: #d11616;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
}

.sendWrapper {
  cursor: pointer;
  position: absolute;
  top: 28.19rem;
  left: 9.75rem;
  background-color: var(--color-crimson);
  display: flex;
  flex-direction: row;
  padding: var(--padding-3xs) var(--padding-81xl);
  align-items: flex-start;
  justify-content: flex-start;
}
.groupChild10,
.nameParent {
  position: absolute;
  width: 30.25rem;
}
.groupChild10 {
  top: 15.75rem;
  left: 0;
  height: 6.44rem;
  box-sizing: border-box;
  background-color: transparent;
  color: white;
  padding-top: 5rem;
  font-size: 1rem;
  border: none;
  border-bottom: 0.5px solid var(--color-gainsboro);
  resize: none;
}

.nameParent {
  top: 0;
  left: 38.94rem;
  height: 31.44rem;
}
.desktopInner1,
.groupParent13 {
  position: absolute;
  width: 69.19rem;
  height: 31.44rem;
}
.groupParent13 {
  top: 0;
  left: 0;
}
.desktopInner1 {
  top: 628.25rem;
  left: 10.44rem;
  font-size: var(--font-size-6xl);
}
.linkplusIt4 {
  position: absolute;
  top: 0;
  left: 0.25rem;
  line-height: 2rem;
  font-weight: 300;
}
.weHaveUsedContainer {
  position: absolute;
  top: 17.25rem;
  left: 25rem;
  line-height: 1.25rem;
  font-weight: 300;
  text-align: right;
}
.desktopInner2,
.linkplusItContainer {
  position: absolute;
  width: 69.69rem;
  height: 21rem;
}
.linkplusItContainer {
  top: 0;
  left: 0;
}
.desktopInner2 {
  top: 558.81rem;
  left: 10rem;
}
.linkplusItAllRightsReserveWrapper {
  position: absolute;
  top: 0;
  left: 18.38rem;
  width: 18.25rem;
  height: 2rem;
}
/* .groupIcon {
   position: absolute;
  height: 56.25%;
  width: 25.88%;
  top: 21.88%;
  right: 74.12%;
  bottom: 21.88%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%; 
} */
.groupParent16 {
  position: absolute;
  top: 674.31rem;
  left: 20rem;
  width: 50rem;
  height: 2rem;
  font-size: var(--font-size-base);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.copyright {
  font-size: 0.8rem;
  font-weight: 100;
}
.iconSection {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.groupIcon {
  padding: 0 0.5rem;
  color: rgb(255, 3, 3);
  font-size: 0.8rem;
  text-decoration: none;
  font-weight: 100;
}
.caretLeftIcon1 {
  position: absolute;
  top: calc(50% - 3.5px);
  right: 65.75rem;
  width: 3rem;
  height: 3rem;
  overflow: hidden;
}
.meee23UiuxDesignForAProduIcon3 {
  position: absolute;
  top: 2.88rem;
  left: 0;
  width: 19.31rem;
  height: 12.69rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon4,
.meee23UiuxDesignForAProduIcon5 {
  position: absolute;
  top: 13.94rem;
  left: 2.31rem;
  width: 18.63rem;
  height: 10.13rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon5 {
  top: 5.69rem;
  left: 39.56rem;
  width: 16.69rem;
  height: 9.88rem;
}
.meee23UiuxDesignForAProduIcon6,
.meee23UiuxDesignForAProduIcon7 {
  position: absolute;
  top: 12.56rem;
  left: 43.75rem;
  width: 24.56rem;
  height: 14.5rem;
  object-fit: cover;
}
.meee23UiuxDesignForAProduIcon7 {
  top: 11.31rem;
  left: 12.19rem;
  width: 20.81rem;
  height: 15.38rem;
}
.meee23UiuxDesignForAProduIcon8,
.viberImage2023031415484Icon {
  position: absolute;
  top: 2.88rem;
  left: 20.94rem;
  width: 17.5rem;
  height: 10.88rem;
  object-fit: cover;
}
.viberImage2023031415484Icon {
  top: 16.63rem;
  left: 25.38rem;
  width: 26.13rem;
  height: 8.44rem;
}
.capture61Icon,
.mmm23LaptopPhonePixarStyleIcon {
  position: absolute;
  top: 8rem;
  left: 47.94rem;
  width: 9.5rem;
  height: 20.56rem;
  object-fit: cover;
}
.mmm23LaptopPhonePixarStyleIcon {
  top: 3.75rem;
  left: 53.06rem;
  width: 10.19rem;
  height: 10.19rem;
}
.meee23UiuxDesignForAProduIcon9 {
  position: absolute;
  top: 0;
  left: 13.56rem;
  width: 8.31rem;
  height: 8.31rem;
  object-fit: cover;
}
.createMeALandingpage1,
.meeeeYoungHappyManProgrammIcon {
  position: absolute;
  top: 7.63rem;
  left: 34.31rem;
  width: 13.5rem;
  height: 9rem;
  object-fit: cover;
}
.createMeALandingpage1 {
  top: 9.69rem;
  left: 59.75rem;
  width: 9rem;
  height: 12.94rem;
}
.caretLeftParent {
  position: relative;
  top: calc(50% + 3938.5px);
  width: 68.75rem;
  height: 28.56rem;
  left: 10%;
}

.desktop {
  background-color: var(--color-gray-200);
  width: 100vw;
  height: 678.81rem;
  text-align: left;
  font-size: var(--font-size-xl);
  color: var(--color-white);
  font-family: var(--font-poppins);
  display: flex !important;
  justify-content: center !important;
}

/*@media (max-width: 1250px) {*/
/*  .desktop{*/
/*    width: 80rem;*/
/*  }*/
/*}*/
.responsiveTest {
  position: relative;
  margin-right: 22%;
}

.sliderDiv {
  background-color: black;
  height: 20rem;
  text-align: center;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 1.9rem;
  text-align: left;
}

.sliderDiv h2 {
  font-family: "Poppins";
  font-size: 0.8rem;
  font-family: 100;
}
.sliderDiv p{
  font-weight: 100;
  font-size: 0.7rem;
}

.firstImageShowed{
  display: none;
}